<template>
  <div v-if="$apollo.loading">...loading</div>
  <div v-else>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        lazy-src="/media/biz-header.jpg"
        src="/media/header2.jpg"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 75px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white">
                Item Editor
              </div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>
    <div class="main main-raised" style="background:#f1f1f1;">
      <v-form class="form" @submit.stop.prevent="submit">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div>
                <v-card outlined>
                  <v-card-text class="">
                    <div class="row pb-6">
                      <div class="col-lg-6">
                        <div class=" row">
                          <label class="col-5 ">
                            <Strong>Product </Strong></label
                          >
                          <div class="col-7">
                            {{ item.product.name }}
                          </div>
                        </div>
                        <div class=" row">
                          <!-- <label class="col-5 ">
                            <Strong>Attribute Set </Strong></label
                          >
                          <div class="col-7">
                            {{ item.product.attribute_set }}
                          </div> -->
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class=" row">
                          <label class="col-5 ">
                            <Strong>Date Created </Strong></label
                          >
                          <div class="col-7">
                            {{ item.created_at | date_format }}
                          </div>
                        </div>
                        <div class=" row">
                          <label class="col-5 ">
                            <Strong>Global Id </Strong></label
                          >
                          <div class="col-7">
                            {{ item.vendorSku }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <label class="labels" for="#firstName">
                  <strong> Item Name </strong></label
                >

                <v-text-field
                  id="name"
                  v-model="item.Name"
                  placeholder="Item Name"
                  background-color="white"
                  required
                  filled
                ></v-text-field>

                <label class="labels"> <strong> Item SKU # </strong></label>

                <v-text-field
                  v-model="item.sku_id"
                  placeholder="Item SKU"
                  background-color="white"
                  required
                  filled
                  @keyup="lowerCase"
                  @keydown.space.prevent
                ></v-text-field>

                <label class="labels"> <strong> UPC # </strong></label>

                <v-text-field
                  v-model="item.upc"
                  placeholder="Item UPC"
                  background-color="white"
                  filled
                ></v-text-field>
                       <label class="labels"> <strong> MSRP </strong></label>

                <v-text-field
                  v-model="item.msrp"
                  placeholder="Item MSRP"
                  background-color="white"
                  filled
                ></v-text-field>

                <label class="labels"> <strong> Weight in Lbs. </strong></label>

                <div class="col-4">
                  <v-text-field
                    v-model="item.itemWeight"
                    background-color="white"
                    filled
                  >
                  </v-text-field>
                </div>

                <div v-if="currentUser.expert">
                  <label class="labels"> <strong> Description </strong></label>
                  <ckeditor
                    v-model="long_description"
                    :editor="editor"
                    :config="editorConfig"
                  ></ckeditor>

                  <div class="form-group row mt-6">
                    <label class="col-12 col-form-label">
                      <strong> Short Description </strong>
                    </label>

                    <div class="col-12">
                      <ckeditor
                        v-model="short_description"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>

                  <v-expansion-panels class="pt-5" multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-toolbar-title> Attributes</v-toolbar-title>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >UPC 2</label
                                >
                                <div class="col-7">
                                  <input
                                    id="name"
                                    v-model="item.upc2"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-5 col-form-label">ISBN</label>
                                <div class="col-7">
                                  <input
                                    id="isbn"
                                    v-model="item.isbn"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Secondary Name</label
                                >
                                <div class="col-7">
                                  <input
                                    id="Price"
                                    v-model="price"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <!-- <div class="form-group row">
                              <label class="col-5 col-form-label"
                                >URL Key</label
                              >
                              <div class="col-7">
                                <input
                                  id="url"
                                  v-model="url"
                                  class="form-control"
                                  type="text"
                                />
                              </div>
                            </div> -->
                              <div class="form-group row">
                                <label class="col-5 col-form-label">UOM</label>
                                <div class="col-7">
                                  <input
                                    id="number"
                                    v-model="item.uom"
                                    class="form-control"
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Length</label
                                >
                                <div class="col-7">
                                  <input
                                    id="number"
                                    v-model="item.itemLength"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Width</label
                                >
                                <div class="col-7">
                                  <input
                                    id="number"
                                    v-model="item.itemWidth"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-5 col-form-label"
                                  >Height</label
                                >
                                <div class="col-7">
                                  <input
                                    id="number"
                                    v-model="item.ItemHeight"
                                    class="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="d-flex align-items-center">
                <v-btn type="submit" color="success" class=" mr-2">
                  Save Changes
                </v-btn>

                <v-btn @click="$router.go(-1)"> Back </v-btn>
              </div>

              <v-card>
                <v-card-text>
                  <div class="pl-4 pt-4">Status</div>
                  <b-form-select
                    id="input-horizontal"
                    v-model="status"
                    :options="item_options"
                    class="mt-4  pl-2"
                  ></b-form-select>
                  <v-card color="orange">
                    <v-card-text>
                      <p>Inventory in Testing only</p>
                    </v-card-text>
                    <v-switch
                      v-model="item.instock"
                      label="In stock"
                      color="green"
                      class="pl-4 pb-5"
                      hide-details
                    ></v-switch>
                    <v-text-field
                      v-model="item.qty"
                      filled
                      label="Stock Qty"
                    ></v-text-field>
                  </v-card>
                </v-card-text>
              </v-card>
              <div v-if="currentUser.expert">
                <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-toolbar-title> Main Image</v-toolbar-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-img
                        id="image_cover"
                        style="width: 100%; max-height: 480px;"
                        :src="
                          item.main_image
                            ? baseurl + item.main_image.url
                            : '/media/product-placeholder.jpg'
                        "
                      />

                      <v-btn text>Main Image</v-btn>

                      <b-form-file
                        v-model="main_image"
                        :state="Boolean(main_image)"
                        :placeholder="
                          main_image_name == ''
                            ? 'Upload Image'
                            : main_image_name
                        "
                        drop-placeholder="Drop file here..."
                        accept="image/*"
                        @change="imageChange"
                      ></b-form-file>

                      <v-spacer></v-spacer>

                      <v-btn class="" small @click="imageDelete">
                        Delete
                      </v-btn>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-toolbar-title> Image Gallery</v-toolbar-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col
                          v-for="(img, index) in item.additional_image"
                          :key="index"
                          cols="6"
                          sm="6"
                        >
                          <v-img
                            :src="
                              img.url != ''
                                ? baseurl + img.url
                                : 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                            "
                          >
                            <div class="fill-height repeating-gradient">
                              <v-btn
                                icon
                                class="float-right"
                                style="background-color: #00000070;"
                                @click="removeImg(img.id)"
                              >
                                <v-icon style="color: white;">mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-img>
                        </v-col>
                      </v-row>

                      <b-form-file
                        v-model="additional_image"
                        :state="Boolean(additional_image)"
                        :placeholder="'Upload Image'"
                        drop-placeholder="Drop file here..."
                        accept="image/*"
                      ></b-form-file>

                      <v-spacer></v-spacer>
                      <a class="btn btn-warning ml-4" @click="addimageUpload"
                        >Upload</a
                      >
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-toolbar-title> Video Links</v-toolbar-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="form-group row">
                        <label class="col-3 col-form-label text-dark"
                          >Youtube Link</label
                        >
                        <div class="col-7">
                          <input
                            id="Youtube"
                            v-model="item.youtube"
                            class="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-3 col-form-label text-dark"
                          >Vimeo Link</label
                        >
                        <div class="col-7">
                          <input
                            id="vimeo"
                            v-model="item.vimeo"
                            class="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-card color="red">
                <v-card-text>
                  This action can not be undone and will remove related pricing.
                  <v-btn
                    elevation="9"
                    small
                    rounded
                    class=" mt-6  mr-2"
                    @click="deleteItem"
                  >
                    <v-icon left>
                      mdi-delete
                    </v-icon>
                    Delete Item
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <!-- <div>
            <div class="card card-custom mt-5">
              <div class="card-header">
                <div class="card-title">
                  Attribute Set Options
                  <small>** Attrtribute Set NAME here** </small>
                </div>
              </div>

              <div class="card-body">
                <template v-if="attribute_set == 'clothing'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Style #</label>
                        <div class="col-7">
                          <input
                            class="form-control"
                            type="text"
                            id="style"
                            v-model="clothing_style"
                          />
                        </div>
                      </div> -->
            <!-- <div class="form-group row">
                            <label  class="col-5 col-form-label">Season</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="Season"/>
                            </div>
                    </div>

                    <div class="form-group row">
                            <label  class="col-5 col-form-label">Size</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="Price"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Color</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="url"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Gender</label>
                            <div class="col-7">
                                <input class="form-control" type="number"  id="number"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Age Group</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="age"/>
                            </div>
                    </div>
      <div class="form-group row">
                            <label  class="col-5 col-form-label">Silhouette</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="Price"/>
                            </div>
                    </div>
    <div class="form-group row">
                            <label  class="col-5 col-form-label">Label Type</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="label"/>
                            </div>
                    </div>
    <div class="form-group row">
                            <label  class="col-5 col-form-label">Material</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="material"/>
                            </div>
                    </div>
    <div class="form-group row">
                            <label  class="col-5 col-form-label">Collection</label>
                            <div class="col-7">
                                <input class="form-control" type="text"  id="collection"/>
                            </div>
                    </div> -->
            <!-- </div>
                  </div>
                </template>
                <template v-if="attribute_set == 'demo1'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Demo 1</label>
                        <div class="col-7">
                          <input
                            class="form-control"
                            type="text"
                            v-model="demo1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="attribute_set == 'demo2'">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group row">
                        <label class="col-5 col-form-label">Demo 2</label>
                        <div class="col-7">
                          <input
                            class="form-control"
                            type="text"
                            v-model="demo2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import gql from 'graphql-tag'
import GetItems from '@/graphql/queries/GetItems.gql'
import GetItembyId from '@/graphql/queries/GetItembyId.gql'
import UpdateItem from '@/graphql/mutations/UpdateItem.gql'
import UploadMainImage from '@/graphql/mutations/UploadMainImage.gql'
import UpdateAdditionalImage from '@/graphql/mutations/UpdateAdditionalImage.gql'
import DeleteItem from '@/graphql/mutations/DeleteItem.gql'
import DeletePriceListItem from '@/graphql/mutations/DeletePriceListItem.gql'

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  mixins: [validationMixin],
  data: () => ({
    panel: [0, 1],
    main_image: null,
    additional_image: null,
    // additional_images: [],
    main_image_url: '',
    main_image_name: '',
    main_image_id: '',
    uom: '',
    msrp: '',
    itemWidth: '',
    itemHeight: '',
    itemLength: '',
    itemWeight: '',
    vendorSku: '',
    stock: '',
    item_options: [
      { value: 'new', text: 'New' },
      { value: 'pending', text: 'Pending' },
      { value: 'discontinued', text: 'Discontinued' },
      { value: 'active', text: 'Active' },
    ],
    updatedMainImage: '',
    number: '',
    url: '',
    price: '',
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',

    selected: null,

    chips: ['Fall2020', 'Shirts', 'mens camo'],

    editor: ClassicEditor,

    editorConfig: {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
    },
    options: [
      { value: null, text: 'New' },
      { value: 'draft', text: 'Draft' },
      { value: 'awaiting', text: 'Awaiting Approval' },
      { value: 'active', text: 'Active ' },
      { value: 'disco', text: 'Discontinued' },
      { value: 'eol', text: 'End of Life' },
    ],
    logic: ['Active', 'Pending', 'Discontinued', 'NOT Discontinued'],

    dialog: false,
    dialog2: false,
  }),
  apollo: {
    item: {
      query: GetItembyId,
      variables() {
        return { id: this.$route.params.id }
      },
    },
  },

  computed: {
    ...mapGetters(['currentUser']),
    status() {
      if (!this.item.status) {
        return 'new'
      } else {
        return this.item.status
      }
    },
    short_description() {
      if (!this.item.short_description) {
        return this.item.product.short_description
      } else {
        return this.item.short_description
      }
    },
    long_description() {
      if (!this.item.long_description) {
        return this.item.product.long_description
      } else {
        return this.item.long_description
      }
    },
    clothing_style() {
      if (!this.item.clothing_style) {
        return this.item.product.clothing_style
      } else {
        return this.item.clothing_style
      }
    },
    demo1() {
      if (!this.item.demo1) {
        return this.item.product.demo1
      } else {
        return this.item.demo1
      }
    },
    demo2() {
      if (!this.item.demo2) {
        return this.item.product.demo2
      } else {
        return this.item.demo2
      }
    },
  },
  watch: {
    item(newValue) {
      if (newValue && newValue.main_image != null) {
        this.main_image_id = newValue.main_image.id
        this.main_image_url = newValue.main_image.url
        this.main_image_name =
          newValue.main_image.name + newValue.main_image.ext
        this.mainImage = newValue.main_image
      }
    },
  },
  async created() {
    if (this.$route.params.id != '' && this.$route.params.id != undefined) {
      this.getItemTotalCount({
        params:
          'id=' +
          this.$route.params.id +
          '&product.supplier=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        if (res.data == 0) {
          this.$router.push('/pim/list')
        }
      })
    }
  },
  methods: {
    lowerCase() {
      this.item.sku_id = this.itwem.sku_id.toLowerCase()
    },
    ...mapActions(['deleteFile', 'getItemTotalCount']),
    async addimageUpload() {
      const additionalImage = await this.$apollo.mutate({
        mutation: UploadMainImage,
        variables: {
          file: this.additional_image,
        },
      })
      const id = additionalImage.data.upload.id
      const imagesIds = this.item.additional_image.map((i) => {
        return i.id
      })
      const additionalImageIds = [...imagesIds, id]
      await this.$apollo.mutate({
        mutation: UpdateAdditionalImage,
        variables: {
          id: this.$route.params.id,
          additional_image: additionalImageIds,
        },
      })
      await this.$apollo.queries.item.refetch()
    },
    removeImg(id) {
      if (id != '' && id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Are you sure?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.deleteFile({
                id: id,
              }).then(() => {
                this.$apollo.queries.item.refetch()
              })
            }
          })
      }
    },
    imageChange(e) {
      let target = e.target || e.srcElement
      let files = target.files
      let fr = new FileReader()
      fr.onload = function() {
        document.getElementById('image_cover').src = fr.result
      }
      fr.readAsDataURL(files[0])
    },
    imageDelete() {
      this.main_image = ''
      this.main_image_name = ''
      this.main_image_url = ''
    },
    async submit(e) {
      e.preventDefault()

      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        if (
          this.main_image == '' &&
          this.main_image_id != '' &&
          this.main_image_id != undefined
        ) {
          this.deleteFile({
            id: this.main_image_id,
          })
        }
        // check sku id duplication
        let items = await this.$apollo.query({
          query: gql`
            query Items($sku_id: String, $supplier: ID!, $item_id: Int) {
              items: items(
                where: {
                  sku_id: $sku_id
                  product: { supplier: $supplier }
                  id_ne: $item_id
                }
              ) {
                id
                Name
                sku_id
              }
            }
          `,
          variables: {
            sku_id: this.item.sku_id,
            supplier: this.$store.state.auth.user.company_relation,
            item_id: parseInt(this.$route.params.id),
          },
        })
        if (items.data.items.length > 0) {
          this.isLoading = false
          Swal.fire({
            title: '',
            text: 'An item with same SKU/Part # already exists in the company.',
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary',
          })
          return
        }

        if (this.main_image) {
          const image = await this.$apollo.mutate({
            mutation: UploadMainImage,
            variables: {
              file: this.main_image,
            },
          })
          this.updatedMainImage = image.data.upload.id
        } else {
          this.updatedMainImage = this.main_image_id
        }

        await this.$apollo.mutate({
          mutation: UpdateItem,
          variables: {
            id: this.$route.params.id,
            Name: this.item.Name,
            sku_id: this.item.sku_id,
            status: this.status,
            upc: this.item.upc,
            msrp: this.item.msrp,
            upc2: this.item.upc2,
            qty: parseInt(this.item.qty),
            instock: this.item.instock,
            isbn: this.item.isbn,
            youtube: this.item.youtube,
            vimeo: this.item.vimeo,
            itemWeight: this.item.itemWeight
              ? parseFloat(this.item.itemWeight)
              : undefined,
            ItemHeight: this.item.ItemHeight,
            itemWidth: this.item.itemWidth,
            itemLength: this.item.itemLength,
            uom: this.item.uom,
            short_description: this.short_description,
            long_description: this.long_description,
            clothing_style: this.clothing_style,
            demo1: this.demo1,
            demo2: this.demo2,
            main_image: this.updatedMainImage,
          },
          refetchQueries: [
            {
              query: GetItems,
              variables: {
                product: parseInt(this.item.product.id),
                limit: 10,
                start: 0, // TODO use company id
              },
            },
          ],
        })
        await this.$apollo.queries.item.refetch()
        await Swal.fire({
          title: '',
                      timer: 1500,
  timerProgressBar: true, 
          text: 'The item has been successfully updated!',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
        this.$router.go(-1)
      }
    },
    async deleteItem() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          title: 'Confirm',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value == true) {
            if (this.item.pricelist_items.length > 0) {
              this.item.pricelist_items.map(async (priceList) => {
                await this.$apollo.mutate({
                  mutation: DeletePriceListItem,
                  variables: {
                    id: priceList.id,
                  },
                })
              })
            }
            await this.$apollo.mutate({
              mutation: DeleteItem,
              variables: {
                id: this.$route.params.id,
              },
              refetchQueries: [
                {
                  query: GetItems,
                  variables: {
                    product: parseInt(this.item.product.id),
                    limit: 10,
                    start: 0, // TODO use company id
                  },
                },
              ],
            })
            await Swal.fire({
              title: '',
              text: 'The item has been successfully deleted!',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })
            this.$router.go(-1)
          }
        })
    },
  },
}
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
